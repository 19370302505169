<template>
  <div class="gigawhat-estimation_tab">
    <Fiche
      :isVIEWER="isVIEWER"
      :ficheValidator="ficheValidator"
      :tabsFicheValidation="$v.ficheValidator"
      :applicationSectors="applicationSectors"
      :departments="departments"
      :periods="periods"
      :addressFicheIndex="ficheValidator"
      :simulationStatus="simulation.status"
      @setPeriod="setPeriods"
      @workTypeBySector="workTypeBySector"
      @fetchFiches="getFiches"
      @fetchPrecarity="fetchPrecarity"
      @getFormConditions="getFormConditions"
      @checkConditions="checkConditions"
    ></Fiche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { constants } from "@/shared/constants";
import {
  estimationService,
  precarityService
} from "@/views/Simulations/services";
import Fiche from "@/views/Simulations/Fiche.vue";

export default {
  name: "FicheStep",
  components: {
    Fiche
  },
  props: [
    "$v",
    "addressValidator",
    "isVIEWER",
    "isAnArtisan",
    "beneficiary",
    "ficheValidator"
  ],
  computed: {
    ...mapGetters("account", ["getCurrentUser"]),
    ...mapGetters("period", { periods: "getPeriods" }),
    ...mapGetters("simulation", {
      simulation: "getSimulation",
      isEdit: "isEdit"
    })
  },
  data() {
    return {
      departments: constants.DEPARTEMENTS,
      applicationSectors: constants.APPLICATION_SECTORS
    };
  },
  methods: {
    ...mapActions("period", ["getPeriodsAction"]),
    setPeriods(ficheValidator) {
      estimationService.setPeriod(ficheValidator, this.periods);
    },
    workTypeBySector(ficheValidator) {
      ficheValidator.workType.$model = "";
      ficheValidator.ficheNumber.$model = "";
      ficheValidator.fiches.$model = "";
      ficheValidator.workTypes.$model = this.applicationSectors.find(
        s => s.value === ficheValidator.activityArea.$model
      ).types;
    },
    getFiches(ficheValidator) {
      estimationService.fetchFiches(ficheValidator);
    },
    fetchPrecarity(ficheValidator) {
      const periodId = ficheValidator.$model.period.id;
      const departmentCode = ficheValidator.$model.departmentCode;
      precarityService.fetchPrecarity(periodId, departmentCode).then(res => {
        ficheValidator.precarityControl.id.$model = res.id;
        ficheValidator.precarityControl.periodId.$model =
          ficheValidator.$model.period.id;
        ficheValidator.precarityControl.department.$model = res.departementCode;
        ficheValidator.precarityControl.couplePercentagePrecarityByDepartment.$model =
          res.percentHigh;
        ficheValidator.precarityControl.couplePercentageBigPrecarityByDepartment.$model =
          res.percentLow;
      });
    },
    getFormConditions(ficheValidator) {
      estimationService.getFormConditions(ficheValidator);
      this.fetchPrecarity(ficheValidator);
    },
    checkConditions(ficheValidator) {
      estimationService.checkConditions(ficheValidator);
    }
  },
  async mounted() {
    await this.getPeriodsAction();
  }
};
</script>
<style scoped>
.content-div {
  word-wrap: break-word;
  max-height: 125.4px;
  min-height: 125.4px;
  overflow-y: auto;
}
</style>
