<template>
  <component
    :$v="$v"
    :addressValidator="addressValidator"
    :ficheValidator="ficheValidator"
    :isVIEWER="isVIEWER"
    :isAnArtisan="isAnArtisan"
    :is="component"
  ></component>
</template>

<script>
import BeneficiaryStep from "@/views/Simulations/import-mass-estimations/edit-estimation/BeneficiaryStep.vue";
import FicheStep from "@/views/Simulations/import-mass-estimations/edit-estimation/FicheStep.vue";

export default {
  name: "EditEstimationSteper",
  components: { BeneficiaryStep, FicheStep },
  props: [
    "currentTab",
    "$v",
    "addressValidator",
    "ficheValidator",
    "isVIEWER",
    "isAnArtisan",
    "beneficiary"
  ],
  computed: {
    component() {
      let mappings = {
        1: "BeneficiaryStep",
        2: "FicheStep"
      };
      return mappings[this.currentTab];
    }
  }
};
</script>
